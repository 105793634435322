import Image from 'next/image';
import { Box, Typography } from '@mui/material';

import GooglePlay from '@@/images/app/googleplay.png';

export default function BottomBar() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      px={4}
      py={1}
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
      }}
    >
      <Typography variant="body2">
        Copyright &copy; {new Date().getFullYear()} Cartinary. All rights
        reserved.
      </Typography>

      <Box width="100px" height="auto" position="relative">
        <Image
          src={GooglePlay}
          alt="Google play store icon"
          style={{ objectFit: 'cover', width: '100%', height: 'auto' }}
        />
      </Box>
    </Box>
  );
}
