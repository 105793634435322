'use client';
import BottomBar from '@/lib/components/AppLayout/_common/BottomBar';
import SideDrawer from '@/lib/components/AppLayout/_common/SideDrawer';
import { TopBar } from '@/lib/components/AppLayout/_common/TopBar';
import useBreakPoints from '@/lib/hooks/useBreakPoints';
import { settingsActions } from '@/lib/redux/slices/settingsSlice';
import { RootState, dispatch, useSelector } from '@/lib/redux/store';
import { Box, CssBaseline, Stack } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};
export default function AppLayout(props: Props) {
  const { medium: mediumDownwards } = useBreakPoints('down');

  const { miniNav, mobileOpen, isClosing } = useSelector(
    (state: RootState) => state.settingsSlice
  );

  // const { isSuperAdmin, isAdmin } = useSelector(
  //   (state: RootState) => state.userSlice
  // );
  // const [mobileOpen, setMobileOpen] = useState(false);
  // const [isClosing, setIsClosing] = useState(false);

  const handleDrawerClose = () => {
    dispatch(settingsActions.setIsClosing(true));
    dispatch(settingsActions.setMobileOpen(false));
  };

  const handleDrawerTransitionEnd = () => {
    dispatch(settingsActions.setIsClosing(false));
  };

  const handleMobileDrawerToggle = () => {
    if (!isClosing) {
      dispatch(settingsActions.setMobileOpen(!mobileOpen));
    }
  };

  const handleSideDrawer = () => {
    if (mediumDownwards) {
      handleMobileDrawerToggle();
    } else {
      dispatch(settingsActions.toggleMiniNav());
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        backgroundColor: '#F7FAFD',
        overflowX: 'auto',
      }}
    >
      <CssBaseline />

      <SideDrawer
        miniNav={miniNav}
        isMobileOpen={mobileOpen}
        onClose={handleDrawerClose}
        onTransition={handleDrawerTransitionEnd}
      />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Stack
            sx={{
              minWidth: '100%',
              minHeight: '100vh',
            }}
          >
            <TopBar onOpenSidebar={handleSideDrawer} />

            <Box pt={4} p={3} flexGrow={1}>
              {props.children}
            </Box>

            <BottomBar />
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}
