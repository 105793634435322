import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import ICON from '@@/images/home.svg';

export function HomeIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      component={ICON}
      viewBox="0 0 20 20"
      titleAccess="Sabischolar Icon"
      {...props}
    />
  );
}
