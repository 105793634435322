import { List, Grid } from '@mui/material';

import { FC } from 'react';
import NavList from './NavList';
import { SearchBar } from '@/lib/components/AppLayout/_common/SearchBar';

const DashboardNavList: FC<{ open: boolean }> = ({ open }) => {
  return (
    <>
      <List
        sx={{
          fontSize: '0.875rem',
          fontWeight: 400,
          pt: 4,
        }}
      >
        <Grid container direction="column" justifyContent="space-between">
          {/* <Grid item display={{ xs: 'block', md: 'none' }} px={2}>
            <SearchBar />
          </Grid> */}

          <Grid item>
            <NavList open={open} />
          </Grid>

          {/* {open && (
            <Grid item mx={3}>
              <RoleToggleButton />
            </Grid>
          )} */}
        </Grid>
      </List>
    </>
  );
};

export default DashboardNavList;
