import { ROUTES } from '@/lib/constants/routes';
import {
  AttachMoneyOutlined,
  CardMembershipOutlined,
  DashboardOutlined,
  PeopleOutlined,
  SecurityOutlined,
  ScheduleSendOutlined,
} from '@mui/icons-material';
import { Grid, List } from '@mui/material';
import { useMemo } from 'react';
import NavItem, { NavListItem } from './NavItem';
import { useAuth } from '@/lib/contexts/AuthContext';
import { RootState, useSelector } from '@/lib/redux/store';

import { HomeIcon } from '@/lib/components/Icons/HomeIcon';
import OrderIcon from '@/lib/components/Icons/OrderIcon';
import ProductIcon from '@/lib/components/Icons/ProductIcon';
import CustomerOneIcon from '@/lib/components/Icons/CustomerOneIcon';
import CustomersIcon from '@/lib/components/Icons/CustomersIcon';
import WalletSearchIcon from '@/lib/components/Icons/WalletSearchIcon';
import ShopIcon from '@/lib/components/Icons/ShopIcon';
import MessagingIcon from '@/lib/components/Icons/MessagingIcon';

const listItemSx = { width: '16px', margin: 'unset' };

type Props = { open: boolean };

export const userRoutes = [
  {
    name: 'Home',
    link: ROUTES.dashboard,
    icon: <HomeIcon />,
  },
  {
    name: 'Orders',
    link: ROUTES.orders(),
    icon: <OrderIcon />,
  },
  {
    name: 'Products',
    link: ROUTES.products(),
    icon: <ProductIcon />,
  },
  {
    name: 'Customers',
    link: ROUTES.customers(),
    icon: <CustomerOneIcon />,
  },
  {
    name: 'Teams',
    link: ROUTES.teams(),
    icon: <CustomersIcon />,
  },
  {
    name: 'Wallet',
    link: ROUTES.wallet(),
    icon: <WalletSearchIcon />,
  },
  {
    name: 'Storefront',
    link: ROUTES.storefront(),
    icon: <ShopIcon />,
  },
  {
    name: 'Support',
    link: ROUTES.dashboardSupport(),
    icon: <MessagingIcon />,
  },
];

export default function NavList(props: Props) {
  const { isSuperAdmin, isAdmin } = useSelector(
    (state: RootState) => state.userSlice
  );

  const { open } = props;

  const listItems = useMemo(() => {
    const routes: NavListItem[] = [];

    if (!isAdmin) {
      routes.push(...userRoutes);
    }

    if (isAdmin) {
      routes.push(
        {
          name: 'Dashboard',
          link: ROUTES.console,
          icon: <DashboardOutlined sx={listItemSx} />,
        },
        {
          name: 'Users',
          link: ROUTES.users(),
          icon: <PeopleOutlined sx={listItemSx} />,
        }
      );
    }

    if (isSuperAdmin) {
      routes.push(
        {
          name: 'Subscriptions',
          link: ROUTES.subscriptions(),
          icon: <CardMembershipOutlined sx={listItemSx} />,
        },
        {
          name: 'Payments',
          link: ROUTES.consolePayments(),
          icon: <AttachMoneyOutlined sx={listItemSx} />,
        },
        {
          name: 'Notifications',
          link: ROUTES.notifications(),
          icon: <ScheduleSendOutlined sx={listItemSx} />,
        },
        {
          name: 'Roles',
          link: ROUTES.roles(),
          icon: <SecurityOutlined sx={listItemSx} />,
        }
      );
    }

    return routes;
  }, [isSuperAdmin, isAdmin]);

  return (
    <List
      sx={{
        fontSize: '0.875rem',
      }}
    >
      <Grid container direction="column" justifyContent="space-between">
        <Grid item>
          {listItems.map((item, index) => (
            <NavItem open={open} key={index} item={item} />
          ))}
        </Grid>
      </Grid>
    </List>
  );
}
