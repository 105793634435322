import MuiDrawer from '@mui/material/Drawer';

import { CSSObject, styled, Theme } from '@mui/material/styles';

import { DRAWER_WIDTH } from '@/lib/components/AppLayout/_common/constants';
import DashboardNavList from '@/lib/components/AppLayout/_common/DashboardNavList';
import Logo from '@/lib/components/Logo';
import CartinaryCartLogo from '@/lib/components/CartinaryCartLogo';
import { Box, IconButton } from '@mui/material';
import { settingsActions } from '@/lib/redux/slices/settingsSlice';
import { dispatch } from '@/lib/redux/store';
import useBreakPoints from '@/lib/hooks/useBreakPoints';
import { SearchBar } from '@/lib/components/AppLayout/_common/SearchBar';

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: theme.palette.common.white,
  '::-webkit-scrollbar': {
    width: '4px',
  },
  '::-webkit-scrollbar-thumb': {
    background: theme.palette.grey[400],
    // borderRadius: '100px',
  },
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: theme.palette.background.default,
  '::-webkit-scrollbar': {
    width: '4px',
  },
  '::-webkit-scrollbar-thumb': {
    background: theme.palette.grey[400],
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

type Props = {
  miniNav: boolean;
  isMobileOpen: boolean;
  onClose: () => void;
  onTransition: () => void;
};

const DrawerContents = ({ open }: { open: boolean }) => {
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={open ? 2.5 : 2}
        py={2}
      >
        {open ? (
          <Logo sx={{ width: 150 }} />
        ) : (
          <CartinaryCartLogo sx={{ width: 30 }} />
        )}
      </Box>

      <DashboardNavList open={open} />
    </>
  );
};

const SideDrawer = (props: Props) => {
  const { miniNav, isMobileOpen, onClose, onTransition } = props;
  const open = !miniNav;
  const { medium: mediumDownwards } = useBreakPoints('down');

  return (
    <>
      {/* Mobile */}
      {mediumDownwards && (
        <MuiDrawer
          variant="temporary"
          open={isMobileOpen}
          onClose={onClose}
          onTransitionEnd={onTransition}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: DRAWER_WIDTH,
              flexShrink: 0,
              whiteSpace: 'nowrap',
            },
          }}
        >
          <DrawerContents open={isMobileOpen} />
        </MuiDrawer>
      )}

      {/* Medium screens and above */}
      {!mediumDownwards && (
        <Drawer variant="permanent" open={open}>
          <DrawerContents open={open} />
        </Drawer>
      )}
    </>
  );
};

export default SideDrawer;
