import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import ICON from '@@/images/search-normal.svg';

export default function SearchIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      component={ICON}
      viewBox="0 0 24 24"
      titleAccess="Cartinary Icon"
      {...props}
    />
  );
}
