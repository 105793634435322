import { NameTag } from '@/lib/components/AppLayout/_common/NameTag';
import { SearchBar } from '@/lib/components/AppLayout/_common/SearchBar';
import { ShortCut } from '@/lib/components/AppLayout/_common/ShortCut';
import AlignLeftIcon from '@/lib/components/Icons/AlignLeftIcon';
import useBreakPoints from '@/lib/hooks/useBreakPoints';
// import { userActions } from '@/lib/redux/slices/userSlice';
import { Box, IconButton } from '@mui/material';
import { FC } from 'react';

type Props = {
  onOpenSidebar: () => void;
};

export const TopBar: FC<Props> = (props) => {
  const { medium: mediumDownwards } = useBreakPoints('down');

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      px={{ xs: 2, md: 4 }}
      py={1}
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
      }}
    >
      <IconButton onClick={props.onOpenSidebar}>
        <AlignLeftIcon />
      </IconButton>

      {!mediumDownwards && <SearchBar />}

      <Box display="flex" alignItems="center" columnGap={2}>
        {!mediumDownwards && <ShortCut />}

        {/* <Notification /> */}

        <NameTag />
      </Box>
    </Box>
  );
};
