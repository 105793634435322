import SearchIcon from '@/lib/components/Icons/SearchIcon';
import useTable from '@/lib/hooks/useTable';
import { Box, FormControl, InputAdornment, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

export function SearchBar() {
  const { search, setSearch } = useTable();

  const [filter, setFilter] = useState(search);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setSearch(filter);
    }, 1000);

    return () => timeOut && clearTimeout(timeOut);
  }, [filter, setSearch]);

  return (
    <Box width={{ md: '50%' }}>
      <FormControl fullWidth>
        <TextField
          id="search"
          variant="outlined"
          autoComplete="off"
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon
                  sx={{ fontSize: 24, border: '2px solid red', color: 'red' }}
                />
              </InputAdornment>
            ),
          }}
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value);
          }}
        />
      </FormControl>
    </Box>
  );
}
