'use client';
import { getProfileApi } from '@/lib/_apis/authentication';
import { LoadingPage } from '@/lib/components/LoadingPage';
import {
  clearAuthData,
  getValidAccessToken,
  getValidRefreshToken,
} from '@/lib/constants/jwt';
import { ROUTES } from '@/lib/constants/routes';
import { useAuth } from '@/lib/contexts/AuthContext';
import { userActions } from '@/lib/redux/slices/userSlice';
import { dispatch, RootState, useSelector } from '@/lib/redux/store';
import { usePathname, useRouter } from 'next/navigation';
import { ComponentType, useEffect, useState } from 'react';

export const withAuth = <T,>(Component: ComponentType<T>) => {
  const HocComponent = (props: T) => {
    const router = useRouter();
    const pathname = usePathname();

    const [checking, setChecking] = useState(true);
    const { profile } = useSelector((state: RootState) => state.userSlice);

    const { toSetPassword } = useAuth();

    useEffect(() => {
      const accessToken = getValidAccessToken();

      if (profile?.id && accessToken) {
        setChecking(false);
        return;
      }

      getProfileApi()
        .then(({ data = null }) => {
          dispatch(userActions.setProfile(data));

          if (!data?.passwordSet) {
            toSetPassword();
            return;
          }

          setChecking(false);
        })
        .catch(() => {
          router.push(ROUTES.auth);
        });
    }, [pathname, profile?.id, router, toSetPassword]);

    return checking ? <LoadingPage /> : <Component {...(props as any)} />;
  };

  return HocComponent;
};
