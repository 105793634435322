import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import ICON from '@@/images/logos/logo-cart.svg';

export default function CartinaryCartLogo(props: SvgIconProps) {
  return (
    <SvgIcon
      component={ICON}
      viewBox="0 0 255 255"
      titleAccess="Cartinary Logo"
      {...props}
      sx={{
        display: 'block',
        width: 100,
        height: 'auto',
        ...(props?.sx || {}),
      }}
    />
  );
}
