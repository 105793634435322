import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import ICON from '@@/images/shop.svg';

export default function ShopIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      component={ICON}
      viewBox="0 0 30 30"
      titleAccess="Cartinary Icon"
      {...props}
    />
  );
}
