import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import ICON from '@@/images/order.svg';

export default function OrderIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      component={ICON}
      viewBox="0 0 20 20"
      titleAccess="Cartinary Icon"
      {...props}
    />
  );
}
